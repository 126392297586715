
.image-slideshow {
    position: relative;
    display: block;
    text-align: center;
    max-width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
}

.slides {
    display: block;
    overflow: hidden;
}

.prev-button {
    display: block;
    background-color: black;
    opacity: 0.3;
    width: 4.0em;

    height: 100%;
    padding-left: 0.5em;
    padding-right: 0.5em;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    z-index: 5060;

    border-radius: 1em;
}


.next-button {
    display: block;
    background-color: black;
    opacity: 0.3;
    width: 4.0em;
    height: 100%;
    padding-left: 0.5em;
    padding-right: 0.5em;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    border-radius: 1em;


    z-index: 5060;

}

.vspan {
    height: calc(50% - 3em);
}

.prev-button img {
    margin-left: -1em;
    width: 6em;
    text-align: center;
}

.next-button img {
    margin-left: -1em;
    width: 6em;
    text-align: center;
}

.slide > span {
    max-height: 40vh;
}

.slide > span img {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 30vh;
    border-radius: 1em;
}


.hidden {
    /* This is used by the slideshow, to hide non-active slides*/
    display: none !important;

}
